<template>
  <div>
    <ServerError v-if="ServerError" /> <PageLoader :storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex xs12 text-center>
        <span class="itemHeading"> Add New {{ dataType }} </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12 lg10 v-if="dataType === 'Scenario'">
        <v-layout wrap justify-start>
          <v-flex xs12 lg6 text-left px-2>
            <span class="itemValue">{{ dataType }}</span>
            <v-text-field
              ref="name"
              color="#FDCE48"
              class="formClass"
              placeholder="Name"
              dense
              v-model="name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 lg6 text-left px-2>
            <span class="itemValue">High</span>
            <v-select
              :items="animalTypes"
              placeholder="High Threat Level"
               item-text="name"
              item-value="name"
              multiple
              dense
              v-model="threatlevel.high"
            ></v-select>
          </v-flex>
          <v-flex xs12 lg6 text-left px-2>
            <span class="itemValue">Medium</span>
            <v-select
              :items="animalTypes"
               item-text="name"
              item-value="name"
               placeholder="Medium Threat Level"
              dense
              multiple
              v-model="threatlevel.medium"
            ></v-select>
          </v-flex>
          <v-flex xs12 lg6 text-left px-2>
            <span class="itemValue">Low</span>
            <v-select
              :items="animalTypes"
              item-text="name"
              item-value="name"
              placeholder="Low Threat Level"
              multiple
              dense
              v-model="threatlevel.low"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="closeDialoge"
              class="itemValue"
            >
              Cancel
            </v-btn>
          </v-flex>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="validateInput"
              class="itemValue"
            >
              <span style="color: #fff">Add {{ dataType }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 lg10 v-else-if="dataType === 'Animals'">
        <v-layout wrap justify-start>
          <v-flex xs12 text-left px-2>
            <span class="itemValue">{{ dataType }} </span>
            <v-text-field
              ref="name"
              color="#FDCE48"
              class="formClass"
              placeholder="Name"
              dense
              v-model="name"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start v-for="(entry, index) in animalEntries" :key="index">
            <v-flex xs12 sm6 text-left px-2>
              <span class="itemValue">Scenario</span>
              <v-select
                :items="getFilteredScenarios(index)"
                item-text="name"
                item-value="_id"
                placeholder="Scenario"
                dense
                v-model="entry.scenario"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6 text-left px-2>
              <span class="itemValue">Threat Level</span>
              <v-select
                :items="ThreatLevelType"
                item-text="name"
                dense
                placeholder="Threat Level"
                v-model="entry.threatlevel"
              ></v-select>
            </v-flex>
          </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="closeDialoge"
              class="itemValue"
            >
              Cancel
            </v-btn>
          </v-flex>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="validateInput"
              class="itemValue"
            >
              <span style="color: #fff">Add {{ dataType }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 lg10 v-else>
        <v-layout wrap justify-start>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">{{ dataType }} </span>
            <v-text-field
              ref="name"
              color="#FDCE48"
              class="formClass"
              placeholder="Name"
              dense
              v-model="name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2 v-if="dataType === 'Crop Types'" pt-4>
            <v-checkbox v-model="isExtent" label="Is Extend?"></v-checkbox>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="closeDialoge"
              class="itemValue"
            >
              Cancel
            </v-btn>
          </v-flex>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="validateInput"
              class="itemValue"
            >
              <span style="color: #fff">Add {{ dataType }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["dataType", "addUrl", "subItemId"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      isExtent: false,
      name: null,
      threatlevel: {
        high: [],
        medium: [],
        low: [],
      },
      animalTypes: [],
      ScenarioType: [],
      ThreatLevelType: ["high", "medium", "low"],
      animalEntries: [],
    };
  },
  beforeMount() {
    this.getData();
    this.getanimalData();
  },
  computed: {
    getFilteredScenarios() {
      return (index) => {
        const selectedScenarios = this.animalEntries.map((entry) => entry.scenario);
        return this.ScenarioType.filter(
          (scenario) => !selectedScenarios.includes(scenario._id) || scenario._id === this.animalEntries[index].scenario
        );
      };
    },
  },
  methods: {
    closeDialoge() {
      this.$emit("stepper", {
        type: "Add",
        getData: false,
        addUser: false,
      });
    },
    validateInput() {
      if (!this.name) {
        this.msg = "Please Provide " + this.dataType + " name";
        this.showSnackBar = true;
        return;
      } else {
        this.addDynamicData();
      }
    },
    addDynamicData() {
      var data = {};
      data["name"] = this.name;
      if (this.dataType == "Mitigation Method")
        data["mitigationTypeId"] = this.subItemId;
      if (this.dataType == "Crop Types") data["isExtent"] = this.isExtent;
      if (this.dataType == "Scenario") data["threatLevels"] = this.threatlevel;
      if (this.dataType == "Animals") data["scenarios"] = this.animalEntries;
      axios({
        method: "post",
        url: this.addUrl,
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "New " + this.dataType + " Created";
            this.showSnackBar = true;
            this.name = null;
            this.isExtent = null;
            this.threatlevel = "";
            this.animalEntries = this.ScenarioType.map(() => ({
              scenario: "",
              threatlevel: "",
            }));
            this.$emit("stepper", {
              type: "Add",
              getData: true,
              addUser: false,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/scenario/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.ScenarioType = response.data.data;
            this.animalEntries = this.ScenarioType.map(() => ({
              scenario: "",
              threatLevel: "",
            }));
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getanimalData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/animal/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.animalTypes = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>